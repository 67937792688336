import { Box, Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, TextField, Checkbox, FormControlLabel } from "@mui/material";
import ProductCard from "./ProductCard";
import swan from '../../assets/swan.webp';
import max2 from '../../assets/max2.webp';
import fiscat from '../../assets/MAX80.webp';
import pax from '../../assets/pax.webp';
import swanMonitor from '../../assets/swan+monitor.webp';
import fiscatNeon from '../../assets/Neon-black-3.webp';
import birch from '../../assets/birch.webp';
import quickR from '../../assets/QRlogo.png';
import React, {useEffect, useState} from "react";
import {useNotify, useTranslate} from "react-admin";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import DeleteIcon from '@mui/icons-material/Delete';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import dataProvider from "../../components/dataProvider";
import Autocomplete from "@mui/material/Autocomplete";

const Shop = () => {
    const [cart, setCart] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [salesId, setSalesId] = useState('');
    const [companies, setCompanies] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [quickRConsent, setQuickRConsent] = useState(false);
    const notify = useNotify();
    const translate = useTranslate()

    const products = [
        { id: 1, name: 'iMin Swan1 POS terminál', image: swan },
        { id: 2, name: 'iMin Swan1 POS terminál ügyfélkijelzővel', image: swanMonitor },
        { id: 3, name: 'iMin M2 Max POS terminál', image: max2 },
        { id: 4, name: 'Fiscat Max80 adóügyi nyomtató', image: fiscat },
        { id: 5, name: 'Fiscat Neon+ WiFi online pénztárgép', image: fiscatNeon },
        { id: 6, name: 'Birch Blokknyomtató', image: birch },
        { id: 7, name: 'Pax hordozható Androidos bankkártya terminál', image: pax },
        { id: 8, name: 'QuickR QR kód alapú fizetési rendszer', image: quickR },
    ];

    useEffect(() => {
        dataProvider.get("crm/company")
            .then((response) => {
                setCompanies(response.data.list)
                if (response.data.list.length === 1) {
                    setSelectedCompany(response.data.list[0])
                }else {
                    setSelectedCompany(response.data.list.find(company => company.id === parseInt(localStorage.getItem("ShopPosSetId"))))
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    const handleAddToCart = (product) => {
        setCart(prevCart => {
            const existingProduct = prevCart.find(item => item.name === product.name);
            if (existingProduct) {
                return prevCart.map(item =>
                    item.name === product.name
                        ? {...item, quantity: item.quantity + product.quantity}
                        : item
                );
            } else {
                return [...prevCart, { ...product}];
            }
        });
    };

    const handleSubmit=()=>{
        dataProvider.create("crm/create", {data: {id: selectedCompany?.id, devices: cart, salesId}})
            .then((response) => {
                handleDialogClose();
                setCart([]);
                notify("Kollégáink hamarosan felveszik Önnel a kapcsolatot!", "success");
            })
            .catch((error) => {
                handleDialogClose();
                console.error(error);
            });
    };

    const handleQuantityChange = (name, delta) => {
        setCart(prevCart => prevCart.map(item =>
            item.name === name
                ? { ...item, quantity: item.quantity + delta }
                : item
        ).filter(item => item.quantity > 0));
    };

    const handleRemoveFromCart = (name) => {
        setCart(prevCart => prevCart.filter(item => item.name !== name));
    };

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const totalQuantity = cart.reduce((sum, item) => sum + item.quantity, 0);
    const hasQuickRProduct = cart.some(item => item.name === 'QuickR QR kód alapú fizetési rendszer');

    return (
        <Box sx={{paddingLeft: '20px'}}>
            <Autocomplete
                sx={{ width: '300px' }}
                options={companies || []}
                renderInput={(params) => <TextField {...params} label={translate("quickR.company")} variant={"standard"} />}
                getOptionLabel={(option) => option.name || ""}
                value={selectedCompany}
                onChange={(event, newValue) => {
                    setSelectedCompany(newValue)
                    localStorage.setItem("ShopPosSetId", newValue?.id)
                }}
            />
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap', gap: 2, marginY: '30px' }}>
                {products.map(product => (
                    <ProductCard key={product.id} product={product} onAddToCart={handleAddToCart} />
                ))}
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <TextField
                    label="Sales azonosító"
                    variant={'standard'}
                    value={salesId}
                    onChange={(e) => setSalesId(e.target.value)}
                    sx={{ marginBottom: '15px', width: '300px' }}
                />
                <Button sx={{marginBottom: '25px', width: '300px'}} disabled={cart.length === 0} variant="contained" color="secondary" onClick={handleDialogOpen}>
                    <span style={{display: 'flex', gap: 4}}>Kosár megtekintése <ShoppingCartIcon/>({totalQuantity})</span>
                </Button>
            </Box>
            <Dialog open={dialogOpen} onClose={handleDialogClose}>
                <DialogTitle>Rendelés összesítő</DialogTitle>
                <DialogContent>
                    {cart.map((item, index) => (
                        <>
                            <Box key={index} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
                                <Typography>
                                    {item.name} - {item.quantity} db
                                </Typography>
                                <Box sx={{marginLeft: '20px'}}>
                                    <IconButton onClick={() => handleQuantityChange(item.name, 1)}>
                                        <AddIcon />
                                    </IconButton>
                                    <IconButton onClick={() => handleQuantityChange(item.name, -1)}>
                                        <RemoveIcon />
                                    </IconButton>
                                    <IconButton onClick={() => handleRemoveFromCart(item.name)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Box>
                            </Box>
                            <hr/>
                        </>
                    ))}
                    {!selectedCompany && (
                        <Typography color="error" sx={{ mt: 2 }}>
                            Kérjük válasszon céget a bal felső legördülő menüben
                        </Typography>
                    )}
                    {hasQuickRProduct && (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={quickRConsent}
                                    onChange={(e) => setQuickRConsent(e.target.checked)}
                                />
                            }
                            label="Kifejezetten hozzájárulok ahhoz, hogy az általam megadott személyes adataimat a QuickR Kft. (cjsz.: 01 09 298382) részére ajánlatadás céljából továbbítsák."
                            sx={{ mt: 2 }}
                        />
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                        Mégse
                    </Button>
                    <Button disabled={!selectedCompany || (hasQuickRProduct && !quickRConsent)} onClick={handleSubmit} color="primary">
                        Megrendelés
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default Shop;